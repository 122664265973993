import React from 'react';
import { Box, Clickable } from '@mentimeter/ragnar-ui';
import { DecoratedNextImage } from 'src/components/image/DecoratedNextImage';
import { contentfulImageLoader } from 'src/cms/utils/utils';
import type { IRating } from 'types/generated/contentful';
import { useMatch } from '@mentimeter/ragnar-device';
import type { ThemeT } from '../Theme';
import { Section, Limit, Wide } from '../layout';
import { H2, H3 } from '../typography';
import { Carousel } from './carousel/Carousel';

export interface RateT extends ThemeT {
  rateTitle: string | undefined;
  rateItems: Array<IRating>;
}

interface StarProps {
  filled: number;
  clipPathId: string;
}

const SizeWidth = 24;
const SizeHeight = 24;

const Star: React.FC<StarProps> = ({ filled, clipPathId }) => (
  <svg
    role="img"
    width={SizeWidth}
    height={SizeHeight}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id={clipPathId}>
        <rect x="0" y="0" width={22 * filled} height={SizeHeight} />
      </clipPath>
    </defs>

    {/* Outlined star */}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 0C11.8806 0 12.2283 0.216072 12.3967 0.557376L15.2543 6.34647L21.6447 7.28051C22.0212 7.33555 22.3339 7.59956 22.4513 7.96157C22.5687 8.32358 22.4704 8.72083 22.1978 8.98636L17.5746 13.4894L18.6656 19.851C18.73 20.2261 18.5757 20.6053 18.2678 20.8291C17.9598 21.0528 17.5515 21.0823 17.2146 20.9051L11.5 17.8998L5.78548 20.9051C5.44856 21.0823 5.04027 21.0528 4.7323 20.8291C4.42432 20.6053 4.27007 20.2261 4.33442 19.851L5.42551 13.4894L0.802296 8.98636C0.529678 8.72083 0.431405 8.32358 0.548783 7.96157C0.666161 7.59956 0.978841 7.33555 1.3554 7.28051L7.74577 6.34647L10.6033 0.557376C10.7718 0.216072 11.1194 0 11.5 0ZM11.5 3.25925L9.30674 7.70262C9.1612 7.99747 8.88001 8.20193 8.55466 8.24949L3.64844 8.9666L7.19776 12.4236C7.43364 12.6534 7.5413 12.9845 7.48564 13.309L6.64821 18.1917L11.0346 15.8849C11.326 15.7317 11.6741 15.7317 11.9655 15.8849L16.3518 18.1917L15.5144 13.309C15.4588 12.9845 15.5664 12.6534 15.8023 12.4236L19.3516 8.9666L14.4454 8.24949C14.12 8.20193 13.8389 7.99747 13.6933 7.70262L11.5 3.25925Z"
    />

    {/* Filled star */}
    <path
      d="M12.3967 0.557376C12.2283 0.216072 11.8806 0 11.5 0C11.1194 0 10.7718 0.216072 10.6033 0.557376L7.74577 6.34647L1.3554 7.28051C0.978841 7.33555 0.666161 7.59956 0.548783 7.96157C0.431405 8.32358 0.529678 8.72083 0.802296 8.98636L5.42551 13.4894L4.33442 19.851C4.27007 20.2261 4.42432 20.6053 4.7323 20.8291C5.04027 21.0528 5.44856 21.0823 5.78548 20.9051L11.5 17.8998L17.2146 20.9051C17.5515 21.0823 17.9598 21.0528 18.2678 20.8291C18.5757 20.6053 18.73 20.2261 18.6656 19.851L17.5746 13.4894L22.1978 8.98636C22.4704 8.72083 22.5687 8.32358 22.4513 7.96157C22.3339 7.59956 22.0212 7.33555 21.6447 7.28051L15.2543 6.34647L12.3967 0.557376Z"
      fill="#101834"
      clipPath={`url(#${clipPathId})`}
    />
  </svg>
);

const getFill = (curr: number, val: number) =>
  val <= curr ? 1 : Math.max(1 - (val - curr), 0);

const RatingItem: React.FC<{ item: IRating; idx: number }> = ({
  item,
  idx,
}) => {
  const { fields } = item;
  return (
    <Clickable
      alignItems="center"
      flex="1 0.5 1"
      mb={0}
      px={[null, 3, 4]}
      width={[1, 1 / 3]}
      href={item.fields.link}
      extend={() => ({
        transition: 'transform 0.3s ease',
        '@media (hover: hover)': {
          ':hover': {
            opacity: 1,
            transform: 'translateY(-8px)',
          },
        },
        ':active': {
          opacity: 1,
        },
      })}
    >
      {fields.icon && (
        <Box height="128px" width="128px" px={2} mt={[4, 4, 0]}>
          <DecoratedNextImage
            src={fields.icon.fields.image.fields.file.url}
            loader={contentfulImageLoader}
            alt={fields.icon.fields.alt}
            width={100}
            height={100}
          />
        </Box>
      )}
      <Box alignItems="center" mt={4} mb={[4, 4, 0]}>
        <H3 textAlign="center">{fields.title}</H3>

        <Box
          flexDirection="row"
          /// check what this actually says
          aria-label={`${fields.rating} out of 5 stars`}
        >
          {[...Array(5)].map((_, i) => {
            const rating = getFill(fields.rating, i + 1);
            return (
              <Star filled={rating} clipPathId={`clip-${idx}-${i}`} key={i} />
            );
          })}
        </Box>
      </Box>
    </Clickable>
  );
};

export const Rating = ({ rateTitle, rateItems, theme }: RateT) => {
  const isTablet = useMatch({ lessThan: 3 });

  if (isTablet) {
    return (
      <Section display="flex" theme={theme} p={0}>
        <Carousel
          title={rateTitle}
          content={rateItems.map((item, index) => (
            <RatingItem item={item} idx={index} key={index} />
          ))}
        />
      </Section>
    );
  }

  return (
    <Section display="flex" theme={theme}>
      <Wide mb={4} alignItems="center" width="100%">
        <Limit mb={4} alignItems="center">
          <H2>{rateTitle}</H2>
        </Limit>
        <Box flexDirection="row" width="100%">
          {rateItems.map((item, index) => (
            <RatingItem item={item} idx={index} key={index} />
          ))}
        </Box>
      </Wide>
    </Section>
  );
};
